import React, { useState } from 'react';
import './Withdraw.css';
import { Button, CircularProgress, InputAdornment, InputLabel, OutlinedInput, Typography, styled, Alert, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Pix from '../assets/pix.svg';

const ColorButton = styled(Button)(() => ({
    color: 'white',
    borderRadius: '20px',
    height: '40px',
    backgroundColor: '#253b80',
    '&:hover': {
        backgroundColor: '#2997d8',
    },
    '&:disabled': {
        color: 'white',
        opacity: 0.5,
    },
}));

const PixWithdraw = () => {
    const [pixKey, setPixKey] = useState('');
    const [pixKeyError, setPixKeyError] = useState('');
    const [amount, setAmount] = useState('');
    const [amountError, setAmountError] = useState('');
    const [loadingWithdraw, setLoadingWithdraw] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [toastOpen, setToastOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);

    const navigate = useNavigate();

    const handlePixKeyChange = (event) => {
        const value = event.target.value;
        setPixKey(value);

        // Remove todos os caracteres não numéricos
        const numericValue = value.replace(/\D/g, '');

        // Verifica o formato da chave Pix
        if (/^[\w-.]+@[\w-]+\.[a-z]{2,4}$/.test(value)) {
            // Valida e-mail
            setPixKeyError('');
        } else if (/^\d{11}$/.test(numericValue)) {
            // Valida CPF
            const formattedCPF = numericValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            setPixKey(formattedCPF);
            setPixKeyError('');
        } else if (/^\d{10,11}$/.test(numericValue)) {
            // Valida telefone celular
            const formattedPhone = numericValue.length === 10 ?
                numericValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3') :
                numericValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            setPixKey(formattedPhone);
            setPixKeyError('');
        } else {
            setPixKeyError('Formato inválido. Use e-mail, CPF ou telefone.');
        }
    };

    const handleAmountChange = (event) => {
        const value = event.target.value.replace(/[^0-9.,]/g, '');
        setAmount(value);
        // Validação adicional pode ser adicionada aqui
    };

    const onSubmitWithdraw = () => {
        setLoadingWithdraw(true);
        const timeoutId = setTimeout(() => {
            setLoadingWithdraw(false);
            setSuccessDialogOpen(true);
            setSuccessMessage('Pix realizado com sucesso.');
        }, 3000);

        return () => clearTimeout(timeoutId);
    };

    const handleDisabledButtonClick = () => {
        setToastOpen(true);
    };

    return (
        <div className="pix-withdraw">
            <div className='pix-logo'>
                <img src={Pix} width='200px' />
            </div>
            <Typography color="#000000" fontSize="20px">Transferir para sua conta Pix</Typography>
            <div className="input-card">
                <InputLabel htmlFor="pix-key">Chave Pix</InputLabel>
                <OutlinedInput
                    id="pix-key"
                    fullWidth
                    value={pixKey}
                    onChange={handlePixKeyChange}
                    placeholder="E-mail, CPF ou telefone"
                />
                {pixKeyError && <Typography color="red">{pixKeyError}</Typography>}
            </div>
            <div className="input-card">
                <InputLabel htmlFor="amount">Valor</InputLabel>
                <OutlinedInput
                    id="amount"
                    fullWidth
                    startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                    value={amount}
                    onChange={handleAmountChange}
                />
                {amountError && <Typography color="red">{amountError}</Typography>}
            </div>
            <div style={{ marginTop: '30px' }}>
                <ColorButton fullWidth disabled={pixKeyError || amountError || !pixKey || !amount} onClick={onSubmitWithdraw}>
                    {loadingWithdraw ? <CircularProgress style={{ width: '25px', height: '25px', color: 'white' }} /> : "Fazer solicitação de retirada"}
                </ColorButton>
            </div>

            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={() => setToastOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setToastOpen(false)} severity="warning">
                    O sistema está instável.
                </Alert>
            </Snackbar>

            <Dialog
                open={successDialogOpen}
                onClose={() => setSuccessDialogOpen(false)}
            >
                <DialogTitle>Retirada Bem-Sucedida</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {successMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setSuccessDialogOpen(false);
                        navigate('/');
                    }} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PixWithdraw;
