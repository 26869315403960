import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Withdraw from './pages/Withdraw';
import Home2x from './pages/Home2x';
import Home10x from './pages/Home10x';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/api=eyU2FsdGVkX18ZOocOsuNj8woPx1gIKA8kjw04IuQY56aL7ICh9rZBzmAHj3ZkLes4jN_paypal_withdraw_token="U2FsdGVkX18ZOocOsuNj8woPx1gIKA8kjw04IuQY56aL7ICh9rZBzmAHj3ZkLes4jN+YHoHECzfOUzAhEoHYlu5gioYBRCfQVMhudMUJhYdPNGNdDVVVd9TLcNHVFArJt8vMbBNq2jRBrZ84QzV8OB058PesQEiVD6neLESaNRR207wkMSEIlSXH27Tojx/AIDfLtzhAj2cAjYXFl3dROPU49v1hCQc0VPoZHTYGt/pMQHG3NPaeLbJAsG74JYTyasBtrBdUw5JxBB1dTe3MJdspawTQbS0ofooQg9C+UfNTTVHizN+ZjN+WX7TTuTud' element={<Withdraw />} />
        <Route path="/p1a3g4e2x5" element={<Home2x />} />
        <Route path="/p9a8g7e610x2" element={<Home10x />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;