import React, { useState, useEffect, useRef } from "react";
import CryptoJS from "crypto-js";
import '../App.css';
import Icon from "../components/Icon";
import { useNavigate } from "react-router-dom";
import { Alert, TextField } from "@mui/material";
import Modal from "../components/Modal";

const App = () => {
    const [hashes, setHashes] = useState([]);
    const [foundHashes, setFoundHashes] = useState(() => {
        const storedFoundHashes = localStorage.getItem("foundHashes");

        return storedFoundHashes ? JSON.parse(storedFoundHashes) : [];
    });
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timer, setTimer] = useState(null);
    const [mining, setMining] = useState(false);
    const [hashCounter, setHashCounter] = useState(0);
    const [btcToUsd, setBtcToUsd] = useState(null);
    const [loadMessage, setLoadMessage] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [btcCount, setBtcCount] = useState(parseFloat(localStorage.getItem("btcCount")) || 0.0025);
    const [, setDailyBalance] = useState(parseFloat(localStorage.getItem('dailyBalance')) || 0);
    const [lastClickDate, setLastClickDate] = useState(() => {
        const storedDate = localStorage.getItem("lastClickDate");
        return storedDate ? new Date(storedDate) : null;
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isWithdraw, setIsWithdraw] = useState(false)
    const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
    const [isModalOpenTen, setIsModalOpenTen] = useState(false);
    const [licenseInputTwo, setLicenseInputTwo] = useState('');
    const [isValidLicenseTwo, setIsValidLicenseTwo] = useState(true);
    const [licenseInputTen, setLicenseInputTen] = useState('');
    const [isValidLicenseTen, setIsValidLicenseTen] = useState(true);

    const hashesContainerRef = useRef(null);
    const foundHashesContainerRef = useRef(null);
    let interval = null;

    const showLoadingAnimation = () => {
        setTimeout(() => setStatusMessage("Connecting on Web3."), 500);
        setTimeout(() => setStatusMessage("Connecting on Web3.."), 1000);
        setTimeout(() => setStatusMessage("Connecting on Web3..."), 1500);
        setTimeout(() => setStatusMessage("Connecting on Web3"), 2000);
        setTimeout(() => setStatusMessage("Connecting on Web3."), 2500);
        setTimeout(() => setStatusMessage("Connecting on Web3.."), 3000);
        setTimeout(() => setStatusMessage("Connecting on Web3..."), 3500);
        setTimeout(() => setStatusMessage("Connected"), 8000);
        setTimeout(() => setStatusMessage("Searching wallets."), 10000);
        setTimeout(() => setStatusMessage("Searching wallets.."), 10500);
        setTimeout(() => setStatusMessage("Searching wallets..."), 11000);
        setTimeout(() => setStatusMessage("Searching wallets"), 11500);
        setTimeout(() => setStatusMessage("Searching wallets."), 12000);
        setTimeout(() => setStatusMessage("Searching wallets.."), 12500);
        setTimeout(() => setStatusMessage("Searching wallets..."), 13000);
        setTimeout(() => setStatusMessage("Done."), 13500);
    };

    const navigate = useNavigate();

    function navigateWithdraw() {
        if (btcCount >= 0.015) {
            return navigate('/api=eyU2FsdGVkX18ZOocOsuNj8woPx1gIKA8kjw04IuQY56aL7ICh9rZBzmAHj3ZkLes4jN_paypal_withdraw_token="U2FsdGVkX18ZOocOsuNj8woPx1gIKA8kjw04IuQY56aL7ICh9rZBzmAHj3ZkLes4jN+YHoHECzfOUzAhEoHYlu5gioYBRCfQVMhudMUJhYdPNGNdDVVVd9TLcNHVFArJt8vMbBNq2jRBrZ84QzV8OB058PesQEiVD6neLESaNRR207wkMSEIlSXH27Tojx/AIDfLtzhAj2cAjYXFl3dROPU49v1hCQc0VPoZHTYGt/pMQHG3NPaeLbJAsG74JYTyasBtrBdUw5JxBB1dTe3MJdspawTQbS0ofooQg9C+UfNTTVHizN+ZjN+WX7TTuTud');
        }
        return setIsWithdraw(`Quedan $${(0.015 * btcToUsd).toFixed(2) - (btcCount * btcToUsd).toFixed(2)} antes de que se libere su retiro semanal.`);
    }



    const handleStartMining = () => {
        // Ativa a mineração apenas se o botão estiver disponível
        const currentDate = new Date();

        setLoadMessage(true);
        showLoadingAnimation();
        setTimeout(() => {
            setMining(true);
        }, 15000);

        // Verificar se o botão já foi clicado hoje
        if (!isSameDay(currentDate, lastClickDate)) {
            // Se não foi clicado hoje, permitir o clique e salvar a data
            setLastClickDate(currentDate);
            localStorage.setItem("lastClickDate", currentDate.toISOString());
            setIsButtonDisabled(true);

            // Simular o cache de 12 horas
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 12 * 60 * 60 * 1000); // 12 hours in milliseconds
        }
    };

    const isSameDay = (date1, date2) => {
        return (
            date1 &&
            date2 &&
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };

    const handleStopMining = () => {
        setMining(false);
        setStatusMessage("Desconectado");
    };

    const getRandomInterval = () => {
        const intervals = [1, 50]; // 2ms, 200ms, 1s
        const randomIndex = Math.floor(Math.random() * intervals.length);
        return intervals[randomIndex];
    };

    const getRandomTime = () => {
        return Math.floor(Math.random() * 30000) + 30000; // Entre 30 segundos e 60 segundos
    };

    const getRandomValue = () => {
        return Math.random() * (0.00004 - 0.00020) + 0.00020;
    };



    const cleanupHashes = (hashes) => {
        const maxHashes = 200; // Keep a minimum of 200 hashes in the list
        const hashesToRemove = hashes.length - maxHashes;
        if (hashesToRemove > 0) {
            return hashes.slice(hashesToRemove);
        }
        return hashes;
    };

    useEffect(() => {
        const fetchBtcToUsd = async () => {
            // id=ethereum 
            // id=binancecoin

            try {
                const response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd");
                const data = await response.json();

                if (data && data.bitcoin && data.bitcoin.usd) {
                    setBtcToUsd(data.bitcoin.usd);
                    console.log(data)
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchBtcToUsd();
    }, []);

    useEffect(() => {
        window.localStorage.setItem('dolar', btcToUsd)
    }, [btcToUsd])

    useEffect(() => {
        // Atualiza o dailyBalance no localStorage antes de recuperá-lo para o estado inicial
        const savedDailyBalance = localStorage.getItem("dailyBalance");
        const initialDailyBalance = savedDailyBalance ? parseFloat(savedDailyBalance) : 0;
        setDailyBalance(initialDailyBalance);
    }, []);

    useEffect(() => {
        let miningInterval;

        if (mining) {
            miningInterval = setInterval(() => {
                setElapsedTime((prevTime) => prevTime + 1);

                // Verificar se passou 1 hora (3600 segundos)
                if (elapsedTime >= 3600) {
                    setMining(false);
                    setStatusMessage("Atenção: limite diário do servidor atingido");
                }

                // Restante do código...
            }, 1000); // Atualiza a cada segundo
        }

        return () => {
            clearInterval(miningInterval);
        };
    }, [mining, elapsedTime]);


    useEffect(() => {
        if (mining) {
            const randomInterval = getRandomInterval();

            interval = setInterval(() => {
                const newHash = CryptoJS.SHA256(hashes.length.toString()).toString();
                setHashes((prevHashes) => [...prevHashes, newHash]);

                // Verifica a cada hash se deve ser verde (1% de chance)
                if (Math.random() < 0.01) {
                    // Gera uma hash verde com valor aleatório
                    const greenHash = CryptoJS.SHA256(Math.random().toString()).toString();
                    setHashes((prevHashes) => [...prevHashes, greenHash]);

                    // Adiciona um valor aleatório entre 0.00000005 e 0.00000010 BTC quando a hash é verde
                    const randomValue = getRandomValue();
                    setBtcCount((prevCount) => prevCount + randomValue);

                    // Adiciona a hash verde ao array de hashes encontradas
                    setFoundHashes((prevFoundHashes) => [...prevFoundHashes, greenHash]);

                    // Zera o contador
                    setHashCounter(0);
                } else {
                    // Incrementa o contador
                    setHashCounter((prevCounter) => prevCounter + 1);
                }

                // Rola automaticamente para o final do contêiner
                if (hashesContainerRef.current) {
                    hashesContainerRef.current.scrollTop = hashesContainerRef.current.scrollHeight;
                }
            }, randomInterval);

            const timer = setTimeout(() => {
                clearInterval(interval);
                setTimer(null);
            }, getRandomTime());

            setTimer(timer);
        }

        return () => {
            clearInterval(interval);
            clearTimeout(timer);
        };
    }, [mining, hashes, hashCounter]);


    useEffect(() => {
        localStorage.setItem("btcCount", btcCount.toString());
    }, [btcCount]);

    useEffect(() => {
        if (lastClickDate) {
            const currentTime = new Date();
            const timeDifference = currentTime - lastClickDate;
            const cooldownTime = 12 * 60 * 60 * 1000; // 24 hours in milliseconds

            if (timeDifference < cooldownTime) {
                setIsButtonDisabled(true);
                const cooldownTimer = setTimeout(() => {
                    setIsButtonDisabled(false);
                }, cooldownTime - timeDifference);

                return () => clearTimeout(cooldownTimer);
            }
        }
    }, [lastClickDate]);

    useEffect(() => {
        localStorage.setItem("foundHashes", JSON.stringify(foundHashes));
    }, [foundHashes]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const updatedHashes = cleanupHashes(hashes);
            setHashes(updatedHashes);
        }, 3000); // Clean up hashes every 3 seconds

        return () => clearInterval(intervalId);
    }, [hashes]);

    useEffect(() => {
        if (foundHashesContainerRef.current) {
            foundHashesContainerRef.current.scrollTop = foundHashesContainerRef.current.scrollHeight;
        }
    }, [foundHashes]);


    return (
        <div>
            <Modal
                open={isModalOpenTwo}
                onClose={() => {
                    setIsModalOpenTwo(false);
                    setLicenseInputTwo("");
                }}
                content={
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                        <div style={{ margin: '5px 0', fontFamily: 'courier', fontWeight: 700, fontSize: '20px' }}>Cripto Miner 2x</div>
                        <div style={{ margin: '10px 0', fontFamily: 'courier', fontWeight: 600, fontSize: '15px' }}>Ingrese el número de serie para otorgar acceso</div>
                        <TextField
                            style={{ borderBottom: '2px solid green', fontFamily: 'Courier New', fontWeight: 700 }}
                            variant="standard"
                            type="text"
                            value={licenseInputTwo}
                            onChange={(e) => setLicenseInputTwo(e.target.value)}
                        />

                        {!isValidLicenseTwo && (
                            <p style={{ color: "red", fontFamily: 'courier', fontWeight: 700, fontSize: '14px' }}>Licencia invalida. Ingrese en el formato correcto.</p>
                        )}

                        <button
                            style={{ marginTop: '15px', padding: '5px' }}
                            onClick={() => {
                                if ((licenseInputTwo === 'A1B2-C3D4-E5F6-X7Y8-Z9A1-B2C3')) {
                                    setIsValidLicenseTwo(true);
                                    window.localStorage.setItem('license2x', licenseInputTwo)
                                    navigate('/p1a3g4e2x5');
                                } else {
                                    setIsValidLicenseTwo(false);
                                }
                            }}
                        >
                            Confirmar
                        </button>
                    </div>
                }
            />

            <Modal
                open={isModalOpenTen}
                onClose={() => {
                    setIsModalOpenTen(false);
                    setLicenseInputTen("");
                }}
                content={
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                        <div style={{ margin: '5px 0', fontFamily: 'courier', fontWeight: 700, fontSize: '20px' }}>Cripto Miner 10x</div>
                        <div style={{ margin: '10px 0', fontFamily: 'courier', fontWeight: 600, fontSize: '15px' }}>Ingrese el número de serie para otorgar acceso</div>
                        <TextField
                            style={{ borderBottom: '2px solid green', fontFamily: 'Courier New', fontWeight: 700 }}
                            variant="standard"
                            type="text"
                            value={licenseInputTen}
                            onChange={(e) => setLicenseInputTen(e.target.value)}
                        />

                        {!isValidLicenseTen && (
                            <p style={{ color: "red", fontFamily: 'courier', fontWeight: 700, fontSize: '14px' }}>Licencia invalida. Ingrese en el formato correcto.</p>
                        )}

                        <button
                            style={{ marginTop: '15px', padding: '5px' }}
                            onClick={() => {
                                if ((licenseInputTen === 'M9N8-O7P6-Q5R4-G3H2-I1J0-K9L8')) {
                                    setIsValidLicenseTen(true);
                                    window.localStorage.setItem('license10x', licenseInputTen)
                                    navigate('/p9a8g7e610x2');
                                } else {
                                    setIsValidLicenseTen(false);
                                }
                            }}
                        >
                            Confirmar
                        </button>
                    </div>
                }
            />
            {isWithdraw && (
                <div style={{ position: 'absolute', top: '40px', right: '10px', zIndex: 100 }}>
                    <Alert severity="info" onClose={() => { setIsWithdraw('') }}>
                        {isWithdraw}
                    </Alert>
                </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '10px' }}>
                <span style={{ fontSize: '12px', fontWeight: 700, fontFamily: 'courier' }}>[Wallets: {(hashes.length * 5).toFixed(0)}] Found: {btcCount.toFixed(6)} BTC (USD {btcToUsd ? (btcCount * btcToUsd).toFixed(2) : "Carregando..."})</span>
            </div>
            <div>
                {loadMessage && (
                    <span style={{ fontSize: '12px', fontWeight: 700, fontFamily: 'courier' }}><span style={{ color: mining ? "green" : "red" }}>{statusMessage}</span></span>
                )}
            </div>
            <div
                ref={hashesContainerRef}
                style={{ height: "100vh", overflowY: "hidden" }}
            >
                {hashes.map((hash, index) => (
                    <div
                        key={index}
                        style={{
                            color: 'green', fontFamily: 'courier', fontWeight: 700, fontSize: '10px'
                        }}
                    >
                        [{hash.includes("green") ? 'BTC FOUND' : 'wallet check'}]: {hash}
                    </div>
                ))}
            </div>
            <div style={{ width: '100vw', backgroundColor: '#000000', zIndex: '99', position: 'fixed', bottom: 0, display: 'flex', justifyContent: 'space-evenly' }}>
                <button style={{ opacity: isButtonDisabled ? '0.4' : '1', cursor: isButtonDisabled ? 'auto' : 'pointer' }} onClick={handleStartMining} disabled={isButtonDisabled}>
                    Start
                </button>
                <button onClick={() => window.localStorage.getItem('license2x') === 'A1B2-C3D4-E5F6-X7Y8-Z9A1-B2C3' ? navigate('/p1a3g4e2x5') : setIsModalOpenTwo(true)}>
                    2x
                </button>
                <button onClick={navigateWithdraw} style={{ border: 0 }}>
                    <Icon type='money' />
                </button>
                <button onClick={() => window.localStorage.getItem('license10x') === 'M9N8-O7P6-Q5R4-G3H2-I1J0-K9L8' ? navigate('/p9a8g7e610x2') : setIsModalOpenTen(true)}>
                    10x
                </button>
                <button onClick={handleStopMining}>Stop</button>
            </div>
        </div >
    );
};

export default App;
