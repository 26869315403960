import React, { useEffect, useState } from 'react';
import './Withdraw.css';
import { Button, CircularProgress, InputAdornment, InputLabel, OutlinedInput, Typography, styled, Alert, Snackbar } from '@mui/material';
import Paypal from '../assets/paypal.svg';
import { useNavigate } from 'react-router-dom';
import PixWithdraw from './PixWithDraw';

const ColorButton = styled(Button)(() => ({
    color: 'white',
    borderRadius: '20px',
    height: '40px',
    backgroundColor: '#253b80',
    '&:hover': {
        backgroundColor: '#2997d8',
    },
    '&:disabled': {
        color: 'white',
        opacity: 0.5,
    },
}));

const Withdraw = () => {
    const [amount, setAmount] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(true);
    const [amountError, setAmountError] = useState('');
    const [btcToUsd, setBtcToUsd] = useState(null);
    const [loadingWithdraw, setLoadingWithdraw] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [withdrawMethod, setWithdrawMethod] = useState('');
    const [toastOpen, setToastOpen] = useState(false);

    const navigate = useNavigate();

    const handleAmountChange = (event) => {
        const value = event.target.value.replace(/[^0-9.,]/g, '');
        setAmount(value);

        if (parseFloat(value) < (0.015 * btcToUsd)) {
            setAmountError(`O valor mínimo é $${0.015 * btcToUsd}`);
        } else {
            setAmountError('');
        }
    };

    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);

        // Expressão regular para validar o formato do e-mail
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(enteredEmail)) {
            setEmailError('Introduza um email válido.');
        } else {
            setEmailError('');
        }
    };

    const btcCount = window.localStorage.getItem('btcCount').toString();
    const is10x = window.localStorage.getItem('license10x');
    const is2x = window.localStorage.getItem('license2x');

    const onSubmitWithdraw = () => {
        const withdrawValue = amount / btcToUsd;
        const newBalance = btcCount - withdrawValue;
        setLoadingWithdraw(true);

        const timeoutId = setTimeout(() => {
            setLoadingWithdraw(false);
            window.localStorage.setItem('btcCount', newBalance);
            setSuccessMessage('O saque foi realizado com sucesso. Seu saque estará em sua conta PayPal dentro de 48 horas.');
        }, 3000);

        return () => clearTimeout(timeoutId);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const fetchBtcToUsd = async () => {
            try {
                const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd');
                const data = await response.json();

                if (data && data.bitcoin && data.bitcoin.usd) {
                    setBtcToUsd(data.bitcoin.usd);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchBtcToUsd();
    }, []);

    const isLicenseUnlocked = is10x ? 0.07 : is2x ? 0.03 : 0.15;

    useEffect(() => {
        const numericValue = parseFloat(amount);
        const minimumAmount = isLicenseUnlocked * btcToUsd;
        const maximumAmount = btcToUsd * btcCount;

        if (numericValue < minimumAmount) {
            setAmountError(`O valor mínimo é $${minimumAmount.toFixed(2)}`);
        } else if (numericValue > maximumAmount) {
            setAmountError(`O valor não pode ser maior que o saldo atual: $${maximumAmount.toFixed(2)}`);
        } else {
            setAmountError('');
        }
    }, [amount, btcToUsd, btcCount]);

    const handleDisabledButtonClick = () => {
        setToastOpen(true);
    };

    return (
        <div className="card">
            {loading ? (
                <div className="wrapper-loading">
                    <div className="spinner">
                        <CircularProgress />
                    </div>
                    <Typography color="#000000">Conectando ao servidor...</Typography>
                </div>
            ) : (
                <>
                    {successMessage && (
                        <div style={{ position: 'absolute', top: '40px', right: '10px', zIndex: 100 }}>
                            <Alert severity="success" onClose={() => { navigate('/'); setSuccessMessage(''); }}>
                                {successMessage}
                            </Alert>
                        </div>
                    )}

                    {!withdrawMethod ? (
                        <div className="withdraw-method-buttons">
                            <div className='withdraw-method-title'>
                                SELECIONE O SEU MÉTODO DE PAGAMENTO:
                            </div>
                            <Button variant="contained" color="primary" onClick={() => setWithdrawMethod('paypal')}>
                                PayPal
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => setWithdrawMethod('pix')}>
                                Pix
                            </Button>
                            <Button variant="contained" color="primary" disabled onClick={handleDisabledButtonClick}>
                                Transferência Bancária
                            </Button>
                            <Button variant="contained" color="primary" disabled onClick={handleDisabledButtonClick}>
                                Carteira Binance
                            </Button>
                        </div>
                    ) : withdrawMethod === 'paypal' ? (
                        <>
                            <div className="logo">
                                <img alt="Paypal logo" src={Paypal} width="200px" />
                            </div>
                            <div className="title">
                                <Typography color="#000000" fontSize="20px">Transferir para sua conta PayPal</Typography>
                            </div>
                            <div>
                                <Typography color="#000000" fontWeight={700}>Saldo: {(btcToUsd * btcCount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Typography>
                            </div>
                            <div className="input-card">
                                <InputLabel htmlFor="outlined-adornment-amount">Valor</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    fullWidth
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={amount}
                                    onChange={handleAmountChange}
                                />
                                {amountError && <Typography color="red">{amountError}</Typography>}
                            </div>
                            <div className="input-card">
                                <InputLabel htmlFor="outlined-basic">Email do PayPal</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    onBlur={() => setEmailError(emailError ? 'Email inválido' : '')}
                                />
                                {emailError && <Typography color="red">{emailError}</Typography>}
                            </div>
                            <div className="fee">
                                <Typography color="#000000" fontSize="13px" fontWeight={700}>Taxa: <span>Grátis</span></Typography>
                                <Typography color="gray" fontSize="12px">Pagamento em: <span>2 dias úteis</span></Typography>
                            </div>
                            <div className="payment-warning">
                                <Typography color="gray" fontSize="12px">Os pedidos de retirada feitos nos fins de semana ou feriados podem demorar um pouco mais. Eles estarão sujeitos a revisão e podem ocorrer atrasos.</Typography>
                            </div>
                            <div>
                                <ColorButton fullWidth disabled={amountError || emailError || !amount || !email} onClick={() => onSubmitWithdraw()}>
                                    {loadingWithdraw ? <CircularProgress style={{ width: '30px', height: '30px', color: 'white' }} /> : "Fazer solicitação de retirada"}
                                </ColorButton>
                            </div>
                        </>
                    ) : withdrawMethod === 'pix' && (
                        <PixWithdraw />
                    )}
                </>
            )}

            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={() => setToastOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setToastOpen(false)} severity="warning">
                    O sistema está instável. Tente novamente mais tarde.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Withdraw;
