import React from 'react';

const Icon = ({ type }) => {
    let svg;

    switch (type) {
        case 'money':
            svg = (
                <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="50px"
                    width="80px"
                >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-3.5-6H14a.5.5 0 100-1h-4a2.5 2.5 0 110-5h1V6h2v2h2.5v2H10a.5.5 0 100 1h4a2.5 2.5 0 110 5h-1v2h-2v-2H8.5v-2z" />
                </svg>
            );
            break;

        case 'info':
            svg = (
                <svg
                    viewBox="0 0 1024 1024"
                    fill="currentColor"
                    height="35px"
                    width="35px"
                >
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                    <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z" />
                </svg>
            );
            break;

        case 'logout':
            svg = (
                <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    height="35px"
                    width="35px"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M14 8V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2h7a2 2 0 002-2v-2" />
                    <path d="M7 12h14l-3-3m0 6l3-3" />
                </svg>
            );
            break;

        default:
            svg = null;
    }

    return (
        <div>
            {svg}
        </div>
    );
};

export default Icon;