// Modal.js

import React from 'react';
import { Modal as MuiModal, Backdrop, Fade, Paper, styled } from '@mui/material';

const StyledModal = styled(MuiModal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const StyledPaper = styled(Paper)({
    backgroundColor: 'white', // Altere a cor de fundo conforme necessário
    border: '2px solid #000',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Altere a sombra conforme necessário
    padding: '20px',
});

const Modal = ({ open, onClose, content }) => {
    return (
        <StyledModal
            open={open}
            onClose={onClose}
            closeAfterTransition
            closeonbackdropclick={false}
        >
            <Fade in={open}>
                <StyledPaper>
                    {content}
                </StyledPaper>
            </Fade>
        </StyledModal>
    );
};

export default Modal;
